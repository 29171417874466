<template>
  <standard-layout>
    <template #heading><h1>Impressum</h1></template>
    <template #main>
      <div class="row">
        <div class="col-12 col-md-4">
          <h5>So erreichst Du uns</h5>
          SPABoot<br/>
          Nicole Kempe<br/>
          Hafenstraße 1<br/>
          04463 Großpösna<br/>
          <a :href="'tel:' + $store.getters.configuration.phone">
            <font-awesome-icon icon="mobile-alt"/>&nbsp;{{ $store.getters.configuration.phone }}</a>.
          <span class="d-md-none">
            <cld-image public-id="email.png" size="36%"/>
          </span>
          <span class="d-none d-md-flex">
            <cld-image public-id="email.png" size="80%"/>
          </span>
          <router-link :to="{name: 'contact'}">
            <button class="btn btn-primary mt-2">
              Kontakt aufnehmen
            </button>
          </router-link>
        </div>
        <div class="col-12 col-md-8 mt-3 mt-md-0">
          <GettingThere/>
        </div>
        <div style="margin-top: 2rem">
          <LeaderBanner/>
        </div>
      </div>
    </template>
  </standard-layout>
</template>

<script>
import StandardLayout from "@/layouts/StandardLayout";
import GettingThere from "@/components/GettingThere";
import LeaderBanner from "@/components/LeaderBanner";

export default {
  components: {
    LeaderBanner,
    GettingThere,
    StandardLayout
  }
}
</script>
